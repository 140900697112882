<template>
  <div>
    <div class="selected-files" v-if="this.selectedFiles.length > 0">
      <h4>Selected Files</h4>
      <div v-for="file,index in this.selectedFiles" :key="index">
        <div class="selected-file">{{ file.url }}</div>
      </div>
      <button type="button" @click="uploadFiles">Upload Files to Layer</button>
    </div>
    <h1>Add Files to <strong>{{ this.layer_name }}</strong><Collapser @toggleElement="handleClose" /></h1>
    <div class="workflow-job-explorer">
      <div>
        <table>
          <thead>
            <th>Workflow</th>
          </thead>
          <tbody>
            <tr v-if="projectWorkflowsLoading"><font-awesome-icon :icon="['fas', 'spinner']"/></tr>
            <tr v-for="workflow, index in workflows" :key="index">
              <td v-if="!projectWorkflowsLoading" :class="{selected: workflow.workflow_id===this.selectedWorkflowId}" @click="handleSelectWorkflow(workflow.workflow_id)">{{ workflow.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <thead>
            <th>Steps</th>
          </thead>
          <tbody v-if="projectStepsLoading">
            <tr><font-awesome-icon :icon="['fas', 'spinner']"/></tr>
          </tbody>
          <tbody v-if="!projectStepsLoading && steps!==undefined">
            <tr v-for="step, index in steps" :key="index">
              <td :class="{selected: step.step===this.selectedStep}" @click="handleSelectWorkflowStep(step.step)">{{ step.step }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>          
        <table>
          <thead>
            <th>Jobs</th>
          </thead>
          <tbody v-if="projectJobsLoading">
            <tr><font-awesome-icon :icon="['fas', 'spinner']"/></tr>
          </tbody>
          <tbody v-if="!projectJobsLoading">
            <tr v-for="job, index in jobs" :key="index">
              <td :class="{selected: job.id===this.selectedJobId}" @click="handleSelectWorkflowJob(job)">{{ job.ui_id }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <thead>
            <th>Datasets</th>
          </thead>
          <tbody v-if="projectFilesLoading">
            <tr><font-awesome-icon :icon="['fas', 'spinner']"/></tr>
          </tbody>
          <tbody v-if="!projectFilesLoading">
            <tr v-for="file, index in files" :key="index">
              <td :class="{selected: file.dataset===this.selectedDataset}" @click="handleSelectWorkflowDataset(file)">{{ file.dataset }} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <thead>
            <th>Files</th>
          </thead>
          <tbody v-if="projectURLsLoading">
            <tr><font-awesome-icon :icon="['fas', 'spinner']"/></tr>
          </tbody>
          <tbody v-if="!projectURLsLoading">
            <tr v-for="url, index in urls" :key="index">
              <td @click="handleSelectWorkflowFiles(url)">{{ url.url }} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Collapser from '../Generics/Collapser.vue'

export default {
  name: 'WorkflowJobExplorer',
  props: {
    username: String,
    password: String,
    layer_name: String,
    project_name: String
  },
  components: {
    Collapser
  },
  data(){
    return {
      selectedWorkflowId: '',
      selectedStep: '',
      selectedJobId: '',
      selectedDataset: '',
      selectedFiles: []
    }
  },
  computed: {
    ...mapGetters({
      workflows: "getProjectWorkflows",
      steps: "getProjectWorkflowSteps",
      jobs: "getJobsForPortalProject",
      files: "getFilesForPortalProject",
      urls: "getURLSforPortalProject",
      projectStepsLoading: "getProjectStepsLoading",
      projectWorkflowsLoading: "getProjectWorkflowsLoading",
      projectJobsLoading: "getProjectJobsLoading",
      projectFilesLoading: "getProjectFilesLoading",
      projectURLsLoading: "getProjectURLsLoading"
    })
  },
  methods: {
    ...mapActions([
      'loadWorkflowsForPortalProject', 
      'loadWorkflowStepsForPortalProject', 
      'loadJobsForPortalProject',
      'loadFilesForPortalProject',
      'clearWorkflowStepData',
      'loadURLsForPortalProject',
      'uploadFilesToLayer'
    ]),
    async handleSelectWorkflow(workflowId){
      this.selectedWorkflowId = workflowId
      await this.loadWorkflowStepsForPortalProject(workflowId)
    },
    async handleSelectWorkflowStep(workflowStep){
      let workflowId = this.selectedWorkflowId
      this.selectedStep = workflowStep
      await this.loadJobsForPortalProject({workflowStep, workflowId})
    },
    async handleSelectWorkflowJob(job){
      let workflowId = this.selectedWorkflowId
      let step = this.selectedStep
      let jobId = job.id
      this.selectedJobId = job.id
      await this.loadFilesForPortalProject({workflowId,step,jobId})
    },
    async uploadFiles(){
      let fileSendModel = {username: this.username, password: this.password, files: this.selectedFiles, project: this.project_name, layer: this.layer_name}
      await this.uploadFilesToLayer(fileSendModel)
    },
    handleSelectWorkflowDataset(dataset){
      this.selectedDataset = dataset.dataset
      this.loadURLsForPortalProject(dataset.dataset)
    },
    handleSelectWorkflowFiles(file){
      let selectedFilesIncludesFile = this.selectedFiles.includes(file)
      if(!selectedFilesIncludesFile){
        this.selectedFiles.push(file)
      }
      else{
        this.selectedFiles = this.selectedFiles.filter(j => j !== file)
      }
    },
    handleClose(){
      this.$emit('handleClose')
    }
  },
  async mounted () {
    await this.clearWorkflowStepData()
    await this.loadWorkflowsForPortalProject(this.project_name)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading-component {
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
}

.workflow-job-explorer{
  cursor:pointer;
  display: flex;
}

.workflow-explorer-table{
  height:1rem;
}

th{
  height: 1rem;
}
.workflow-job-explorer td:hover{
  background-color: rgba(150,150,150,0.25);
  color:rgba(0, 0, 0, 0.6)
}
.workflow-job-explorer td:active{
  background-color: rgba(150,150,150,0.5);
  color:rgba(0, 0, 0, 0.9)}
.project-select{
  margin:auto;
  text-align: center;
}
.project-select-items{
  display: inline-flex;
  justify-content: center;
  margin: 1rem;  
}

.selected{
  background-color: rgba(150,150,150,1);
}

.selected-files{
  padding:0.5rem;
  /* display: flex; */
}

.selected-file{
  padding:0.5rem;
  background:rgba(150,150,150,0.5);
  border:0.2rem solid white;
  border-radius: 1rem;
}

</style>
