<template>
  <div class="portal">
    <h1>Portal</h1>
    <h2>{{ this.projectName }}</h2>
    <div class="portal-main">
      <form @submit.prevent="createProject">
        <div class="collapser-header">{{ this.hideProjectDetails? "Project Details":"" }}
          <Collapser @toggleElement="toggleProjectDetails" :hide="this.hideProjectDetails"/>
          <div class="portal-credentials" v-if="!this.hideProjectDetails">
            <div class="form-group">
              <label for="project-name">Name</label>
              <input type="text" v-model="this.projectName" name="project-name" class="form-control" disabled />
            </div>
            <div class="form-group">
              <label for="selectedURI">URI</label>
              <input type="text" v-model="selectedURI" name="username" class="form-control" :class="{ 'is-invalid': submitted && !selectedURI }" />
              <div v-show="submitted && !selectedURI" class="invalid-feedback">selectedURI is required</div>
            </div>
            <div class="form-group">
              <label for="product">Product</label>
              <input type="text" v-model="selectedProduct" name="layer" class="form-control" :class="{ 'is-invalid': submitted && !selectedProduct }" />
              <div v-show="submitted && !selectedProduct" class="invalid-feedback">Product is required</div>
            </div>
            <div v-show="project===null">
              <div class="form-group">
                <button type="submit" class="btn btn-primary app-button primary-button" :class="{'button-loading': submitted}">
                  <span v-if="!this.submitted">Create {{ projectName }}</span>
                  <font-awesome-icon v-if="this.submitted" :icon="['fas', 'spinner']" class="loading-portal-submit"/>
                </button>
              </div>      
            </div>
            <div v-if="this.project!==null && this.project.layers!==undefined" class="form-group">
              <button @click="updateProject" class="btn btn-primary app-button primary-button" :class="{'button-loading': submitted}">
                <span v-if="!this.submitted">Update Project</span>
                <font-awesome-icon v-if="this.submitted" :icon="['fas', 'spinner']" class="loading-portal-submit"/>
              </button>
            </div>
          </div>
        </div>
        <div v-if="this.project!==null && this.project.layers!==undefined && this.project.layers.length>0 &&!this.addingFilesToLayer">
          <h2>Layers</h2>
          <table>
            <thead>
              <th>Name</th>
              <th>Type</th>
            </thead>
            <tbody>
              <tr v-for="layer,index in project.layers" :key="index">
                <td>{{ layer.name }}</td>
                <td>{{ layer.type }}</td>
                <td v-show="!this.addingFilesToLayer" class="add-layer-files" @click="addFilesToLayer(layer)">Add Files</td>
              </tr>
            </tbody>
          </table>
        </div>        
        <div class="add-layer" v-if="project!==null">
          <span class="collapser-header">{{ this.hideAddLayers? "Add Layer":"" }}</span>
          <Collapser @toggleElement="toggleAddLayers" :hide="this.hideAddLayers"/>
          <div class="portal-credentials" v-if="!this.hideAddLayers">
            <h3>Add New Layer</h3>
            <div class="form-group">
              <label for="layer">Name</label>
              <input type="text" v-model="selectedLayerName" name="layer" class="form-control" :class="{ 'is-invalid': submitted && !selectedLayerName }" />
              <div v-show="submitted && !selectedLayerName" class="invalid-feedback">Name is required</div>
            </div>
            <div class="form-group" v-if="layerTypes!==undefined">
              <select id="filter"
                        name="filter"
                        class="form-control mt-1"
                        v-model="selectedLayerType">
                  <option v-for="item, index in layerTypes" v-bind:value="item" :key="index">{{ item }}</option>
                </select>
            </div>
          </div>
          <div v-if="project!==null" class="form-group">
            <button type="button" @click="addNewLayer" v-if="!this.submitted && this.selectedLayerName!=='' && this.selectedLayerType!==''" class="btn btn-primary app-button primary-button" :class="{'button-loading': submitted}">
              <span>Add Layer</span>
              <font-awesome-icon v-if="this.submitted" :icon="['fas', 'spinner']" class="loading-portal-submit"/>
            </button>
          </div>
        </div>
        <div>
          <span class="collapser-header">{{ this.hideCredentials? "Portal Credentials":"" }}</span>
          <Collapser @toggleElement="toggleCredentials" :hide="this.hideCredentials"/>
          <div class="portal-credentials" v-show="!this.hideCredentials">
            <div class="form-group">
              <label for="username">Portal Username</label>
              <input type="text" v-model="selectedUserName" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
              <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
            </div>
            <div class="form-group">
              <label htmlFor="password">Portal Password</label>
              <input type="password" v-model="selectedPassword" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
              <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
          </div>
        </div>
      </form>    
    </div>
    <div v-if="this.addingFilesToLayer" class="file-add">
      <form id="add-files">
       <WorkflowJobExplorer :layer_name="this.selectedLayerForFiles" :username="this.selectedUserName" :password="this.selectedPassword" :project_name="this.projectName" @handleClose="closeFilesAddingToLayer(layer)"/>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SelectList from '../Generics/SelectList.vue'
import Collapser from '../Generics/Collapser.vue'
import Notification from '../Generics/Notification.vue';
import WorkflowJobExplorer from '../PortalConfig/WorkflowJobExplorer.vue'

export default {
  name: 'Portal',
  components: {
    SelectList,
    Notification,
    Collapser,
    WorkflowJobExplorer
  },
  props: {
    projectName: String,
  },
  computed: {
    ...mapGetters({
      project: 'getProject',
      layer: 'getLayer',
      message: 'getMessage',
      layerTypes: 'getlayerTypes'
    }),
  },
  data () {
    return {
        selectedUserName: '',
        selectedPassword: '',
        selectedLayerName: '',
        selectedLayerType: '',
        selectedURI: '',
        selectedProduct: '',
        selectedLayerForFiles: '',
        submitted: false,
        hideCredentials: true,
        hideProjectDetails: false,
        hideAddLayers: true,
        hideLayers: false,
        addingFilesToLayer: false
    }
  },
  methods: {
    ...mapActions(['updatePortalProject', 'addPortalProject', 'loadPortalProjectData', 'addLayer', 'loadLayerTypes']),
    async createProject(){
      this.submitted=true
      if (this.selectedUserName && this.selectedPassword && this.projectName && 
          this.selectedURI && this.selectedProduct) {
            await this.addPortalProject({
              username: this.selectedUserName,
              password: this.selectedPassword,
              name: this.projectName, 
              uri: this.selectedURI,
              product: this.selectedProduct
            }
          )
        }
      this.submitted = false
    },
    async updateProject(){
      this.submitted=true
      if (this.selectedUserName && this.selectedPassword && this.projectName && 
          this.selectedURI && this.selectedProduct) {
            await this.updatePortalProject({
              username: this.selectedUserName,
              password: this.selectedPassword,
              name: this.projectName, 
              uri: this.selectedURI,
              product: this.selectedProduct
            }
          )
        }
      this.submitted = false
    },
    async addNewLayer(){
      this.submitted=true
      if (this.selectedUserName && this.selectedPassword && this.projectName && 
         this.selectedLayerName && this.selectedLayerType) {
            await this.addLayer({
              username: this.selectedUserName,
              password: this.selectedPassword,
              name: this.projectName, 
              layerName: this.selectedLayerName,
              layerType: this.selectedLayerType
            }
          )
        }
      this.clearLayerDetails()
      this.submitted = false
    },
    addFilesToLayer(layer){
      this.selectedLayerForFiles = layer.name
      this.addingFilesToLayer = true
    },
    closeFilesAddingToLayer(layer){
      this.selectedLayerForFiles = ''
      this.addingFilesToLayer = false
    },
    toggleCredentials(){
      this.hideCredentials = !this.hideCredentials
    },
    toggleProjectDetails(){
      this.hideProjectDetails = !this.hideProjectDetails
    },
    toggleAddLayers(){
      this.hideAddLayers = !this.hideAddLayers
    },
    clearLayerDetails(){
      this.selectedLayerName = ''
      this.selectedLayerType = ''
    }
  },
  async mounted(){
    await this.loadLayerTypes()
    await this.loadPortalProjectData(this.projectName)
    if(this.project===null){
      this.selectedURI=this.projectName
      this.selectedProduct="Forest[Now]"
      this.hideAddLayers=true
    }
    else{
      this.selectedURI = this.project.uri,
      this.selectedProduct = this.project.product_name
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
}

.add-layer-files{
  cursor: pointer;
  color:blue
}
.add-layer-files:hover{
  cursor: pointer;
  color:rgb(0, 0, 185);
  background-color: rgba(111, 117, 122, 0.5);
}
.collapser-header{
  font-size: small;
  color:rgb(56, 56, 56)
}

.form-group{
  padding:0.5rem;
}


.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.portal{
  width: 60%;
  justify-content: center;
  margin: 1rem auto;
}

.portal-main{
  width: 50%;
  justify-content: center;
  margin: 1rem auto;
}

.portal-responses{
  text-align: center;
}

.portal-credentials{
  border-radius: 0.5rem;
  border:0.1rem solid lightgray;
  padding:0.5rem;
  background: rgb(214, 214, 214);
}

</style>
